import React, { useState } from "react"
import { Link } from "gatsby"
import { FaAlignJustify } from "react-icons/fa"
import logo from "../assets/images/logo.svg"
const Nav = () => {
  const [show, setshow] = useState(false)
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="Maato Collection Logo" />
          </Link>
          <button
            className="nav-btn"
            onClick={() => {
              setshow(!show)
            }}
          >
            <FaAlignJustify alt="Mobile menu"/>
          </button>
        </div>
        <div className={show ? "nav-links show-links" : "nav-links"}>
          <Link
            to="/"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => {
              setshow(false)
            }}
          >
            home
          </Link>

          <Link
            to="/blogs"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => {
              setshow(false)
            }}
          >
            blogs
          </Link>

          <Link
            to="/tags"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => {
              setshow(false)
            }}
          >
            Keys
          </Link>

          <Link
            to="/about"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => {
              setshow(false)
            }}
          >
           portfolio
          </Link>
          <Link
            to="/contact"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => {
              setshow(false)
            }}
          >
            contact
          </Link>
          {/* <div className="nav-link contact-link">
            <Link
              to="/contact"
              className="btn"
              onClick={() => {
                setshow(false)
              }}
            >
              contact
            </Link>
          </div> */}
        </div>
      </div>
    </nav>
  )
}

export default Nav

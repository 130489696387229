import React from 'react';
import MessengerCustomerChat from "react-messenger-customer-chat";
const MessengerBot = () => (
  <div>
    <MessengerCustomerChat
    pageId="105715634210482"
    appId="275913016246135"
  />
  </div>
);


export default MessengerBot;

import React from 'react'

const Footer = () => {
    return (
        <footer className="page-footer">
                <small>
                    &copy;{new Date().getFullYear()}
                    <span className=""> Maato Collection </span> Used: {""}
                    <p>Gatsby and its plugins, Contentful, Graphql, FrontEnd Web Tech, ReactJS, Helmet, Gits, Netlify </p>
                </small>
        </footer>
    )
}

export default Footer

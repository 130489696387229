import React from 'react'
import Footer from './Footer'
import Nav from './Nav'
import MessengerBot from './MessengerBot';
import 'normalize.css'
import '../assets/css/main.css'

const Layout = ({children}) => {
    return (
        <>
        <Nav/>
        {children}
        <MessengerBot/>
        <Footer/>
        </>
  
    )
}

export default Layout
